import React from 'react'
import { graphql, Link } from 'gatsby'
import { PropTypes } from 'prop-types'
import { jsonId } from 'utils'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import SEO from 'components/seo'
import HelmetExport from 'react-helmet'
import {
  flexContainer,
  flexContainerInner
} from '../styles/resources.module.css'
import {
  innerContainer,
  button,
  description,
  mapWrapper,
  headerText,
  topHeaderText,
  siteMapDivider,
  container
} from 'styles/sitemap.module.css'
import {
  pageWrapper,
  breadcrumbContainer
} from '../styles/professionalServicesChildren.module.css'

export const query = graphql`
  query {
    wordPress {
      pages(where: { title: "Sitemap" }) {
        edges {
          node {
            sitemapFieldGroup {
              sitemapDescription
              whatWeDoHeaderText
              whatWeDoLinks {
                linkTitle
                linkUrl
              }
              aboutUsHeaderText
              aboutUsLinks {
                linkTitle
                linkUrl
              }
              newsAndUpdatesHeaderText
              newsAndUpdatesLinks {
                linkTitle
                linkUrl
              }
            }
            title
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
const SiteMapContent = ({ data, pageContext }) => {
  const sitemapData = data.wordPress.pages.edges[0].node.sitemapFieldGroup

  const pageTitle = data.wordPress.pages.edges[0].node.title
  const siteUrl = data.site.siteMetadata.siteUrl
  const contextSlug = pageContext.slug
  const {
    breadcrumb: { crumbs }
  } = pageContext
  return (
    <>
      <HelmetExport>
        {jsonId({
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: [
            {
              '@type': 'ListItem',
              item: siteUrl,
              name: 'Home',
              position: 1
            },
            {
              '@type': 'ListItem',
              item: `${siteUrl}/${contextSlug}`,
              name: `${pageTitle}`,
              position: 2
            }
          ]
        })}
      </HelmetExport>
      <SEO
        description="This page displays Deimos' various business sections"
        title='Sitemap'
      />
      <section className={pageWrapper}>
        <div className={breadcrumbContainer}>
          <Breadcrumb
            crumbLabel='Sitemap'
            crumbs={crumbs}
            crumbSeparator=' > '
          />
        </div>
        <div className={flexContainer}>
          <div className={flexContainerInner}>
            <h1 className={topHeaderText}>Sitemap</h1>
            <p className={description}>{sitemapData.sitemapDescription}</p>
          </div>
        </div>
      </section>
      <section className={container}>
        <div className={innerContainer}>
          <div>
            <h1 className={headerText}>{sitemapData.whatWeDoHeaderText}</h1>
            <div className={mapWrapper}>
              {sitemapData.whatWeDoLinks.map((item, index) => (
                <Link
                  className={button}
                  key={index.toString()}
                  to={item.linkUrl}
                >
                  {item.linkTitle}
                </Link>
              ))}
            </div>
          </div>
          <div>
            <h1 className={headerText}>{sitemapData.aboutUsHeaderText}</h1>
            <div className={mapWrapper}>
              {sitemapData.aboutUsLinks.map((item, index) => (
                <Link
                  className={button}
                  key={index.toString()}
                  to={item.linkUrl}
                >
                  {item.linkTitle}
                </Link>
              ))}
            </div>
          </div>
          <div className={siteMapDivider}>
            <h1 className={headerText}>
              {sitemapData.newsAndUpdatesHeaderText}
            </h1>
            <div className={mapWrapper}>
              {sitemapData.newsAndUpdatesLinks.map((item, index) => (
                <Link
                  className={button}
                  key={index.toString()}
                  to={item.linkUrl}
                >
                  {item.linkTitle}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

SiteMapContent.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
}
export default SiteMapContent
